import React, { Component } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link, HexaImage } from "."

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px;

    ${up("mobile")} {
      width: 293px;
      align-items: flex-start;
    }
  `}
`
const ImageWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    width: 160px;

    ${up("mobile")} {
      width: 260px;
    }
  `}
`

const Div = styled.div``

class PersonItem extends Component {
  render() {
    const { person } = this.props
    const to = `/${person.slug}`

    const LinkComponent = person.hasDetailPage ? Link : Div

    return (
      <Wrapper>
        <LinkComponent to={to}>
          <ImageWrapper>
            <HexaImage
              image={person.photo.filename.image.gatsbyImageData}
              alt={person.name}
            />
          </ImageWrapper>
        </LinkComponent>
        <Gap gap="13px" mobileGap="16px" />
        <Col>
          <LinkComponent to={to}>
            <Title.SmallerTitle>{person.name}</Title.SmallerTitle>
          </LinkComponent>
          <Gap mobileGap="4px" gap="0px" />
          <Text.Italic>{person.position}</Text.Italic>
          <Gap gap="8px" />
          <Text responsive>{person.perex}</Text>
          <Gap gap="40px" />
        </Col>
      </Wrapper>
    )
  }
}

PersonItem.propTypes = {}

export default PersonItem
