// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

// helpers
import { up, addResponsivity, down } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  VH,
  Listing,
  ContactFormSection,
} from "../components"
import PersonItem from "../components/PersonItem"
import Accordion from "../components/Accordion"
import EllipseContent from "../components/EllipseContent"

// assets
import caretPath from "../data/images/icon-team-caret.svg"

// custom styled components
const DropDownList = styled.div`
  ${({ isOpen, bp = "mobile" }) => css`
    ${down(bp)} {
      padding: 24px;
      border-radius: 12px;
      box-shadow: 0 2px 16px rgba(64, 82, 171, 0.16);
      z-index: 2;
      position: absolute;
      background: white;
      top: 32px;
      display: ${isOpen ? "block" : "none"};
      /* padding: 0;
      border-radius: 0;
      box-shadow: none;
      z-index: inherit;
      display: block;
      position: relative; */
    }
  `}
`
const CaretIcon = styled.img`
  ${({ up }) => css`
    transition: 0.3s;
    transform: rotate(${up ? 180 : 0}deg);
  `}
`

class Team extends React.Component {
  state = {
    openedCategory: "all",
  }

  openCategory = _id => {
    this.setState({
      openedCategory: _id,
      dropDownOpened: false,
    })
  }

  toggleDropDown = () => {
    this.setState(state => ({
      dropDownOpened: !state.dropDownOpened,
    }))
  }

  render() {
    const { openedCategory, dropDownOpened } = this.state

    const {
      location: { pathname },
      intl: { language, t },
    } = this.props

    let { introImage, categories, people } = extractNodesFromDataApplyLangs(
      this.props.data,
      language
    )

    categories = categories.map(c => ({
      ...c,
      people: c.people.filter(p => p.isPublished),
    }))

    // sorting people in categories
    categories.forEach(c => {
      c.people.sort((a, b) => a.sortOrder - b.sortOrder)
    })

    const LISTING_BP = 750

    const categoriesWithAll = [
      {
        _id: "all",
        name: t("Všichni zaměstnanci"),
        people,
      },
      ...categories,
    ]

    return (
      <>
        <SEO
          title={t("team - meta title")}
          description={t("team - meta desc")}
          pathname={pathname}
        />

        <Gap.Top />

        <Row responsive bp={970}>
          <Col shrink="2">
            <Title.PageTitle>
              <T>Náš tým</T>
            </Title.PageTitle>
            <Gap gap="22px" mobileGap="36px" />
            <Text.Intro maxWidth="586px">
              <T raw>team - intro</T>
            </Text.Intro>
          </Col>
          <Gap.Fluid max="83px" min="36px" />
          <Col width="100%" maxWidth="470px" shrink="10">
            <GatsbyImage
              image={introImage.image.gatsbyImageData}
              alt="Team photo"
            />
          </Col>
        </Row>
        <Gap gap="124px" mobileGap="48px" />
        <Col
          width="100%"
          maxWidth="1011px"
          alignItems="flex-start"
          alignSelf="center"
          position="relative"
        >
          {categoriesWithAll.map((cat, index) => (
            <Col
              maxWidth="340px"
              mobileAlignSelf="center"
              alignSelf="flex-start"
              width="100%"
            >
              <VH show={openedCategory === cat._id} key={index}>
                <RH mobile bp={LISTING_BP}>
                  <Link.BasicLink asSpan onClick={this.toggleDropDown}>
                    <Row>
                      <div>{cat.name}</div>
                      <Gap gap="5px" />
                      <CaretIcon src={caretPath} up={dropDownOpened} />
                    </Row>
                  </Link.BasicLink>
                  {/* <Gap gap="24px" /> */}
                </RH>
              </VH>
            </Col>
          ))}

          <DropDownList isOpen={dropDownOpened} bp={LISTING_BP}>
            <Row responsive mobileAlignItems="flex-start" bp={LISTING_BP}>
              {categoriesWithAll.map((cat, index) => (
                <React.Fragment key={cat._id}>
                  {index !== 0 && <Gap gap="40px" onlyWidth />}
                  <Link.BasicLink
                    asSpan
                    isActive={openedCategory === cat._id}
                    onClick={() => {
                      this.openCategory(cat._id)
                      this.setState({ dropDownOpened: false })
                    }}
                  >
                    {cat.name}
                  </Link.BasicLink>
                </React.Fragment>
              ))}
            </Row>
          </DropDownList>
          <Gap gap="40px" mobileGap="24px" bp={LISTING_BP} />
          {categoriesWithAll.map((cat, index) => (
            <Col alignSelf="center" key={index}>
              <Accordion isOpen={cat._id === openedCategory}>
                <EllipseContent maxHeight={2100}>
                  <Listing maxWidth="1011px" gap="66px">
                    {cat.people.map(p => (
                      <PersonItem innerKey={p._id} key={p._id} person={p} />
                    ))}
                  </Listing>
                </EllipseContent>
              </Accordion>
            </Col>
          ))}
        </Col>
        <Gap gap="80px" mobileGap="40px" />
        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(Team)

export const pageQuery = graphql`
  {
    allPeople {
      edges {
        node {
          ...PersonFragment
        }
      }
    }
    categories: allPersoncategories(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          ...PersonCategoryFragment
          people {
            ...PersonFragment
          }
        }
      }
    }
    people: allPeople(
      sort: { fields: sortOrder, order: ASC }
      filter: { isPublished: { eq: true } }
    ) {
      edges {
        node {
          ...PersonFragment
        }
      }
    }
    introImage: file(relativePath: { eq: "images/team-intro-image.png" }) {
      image: childImageSharp {
        gatsbyImageData(
          width: 470
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
